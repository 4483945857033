body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul {
  list-style: none;
}
li {
  list-style: none;
}
a {
  text-decoration: none;
}

/* COLORS */
.darkColor {
  color: #0b093b;
}

.darkColorNew {
  color: #35426D;
}

.purpleColor {
  color: #7620ff;
}

.orangeColor {
  color: #f2b300;
}

.lightColor {
  color: #f5f5f5;
}

.coralColor {
  color: #c4ffff;
}

.greenColor {
  color: #49cb86;
}

.greyColor {
  color: #707070;
}

.whiteColor {
  color: #fff;
}

/* BACKGROUNDS */
.darkBg {
  background-color: #0b093b;
}

.purpleBg {
  background-color: #7620ff;
}

.orangeBg {
  background-color: #f2b300;
}

.lightBg {
  background-color: #f5f5f5;
}

.coralBg {
  background-color: #c4ffff;
}

.greenBg {
  background-color: #49cb86;
}

.greyBg {
  background-color: #707070;
}

.whiteBg {
  background-color: #fff;
}
