html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  box-sizing: border-box;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none !important;
  color: #0b093b;
}

a:hover {
  color: #7620ff;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px #fff inset;
  /* Change the color to your own background color */
  box-shadow: transparent;
  -webkit-text-fill-color: #fff;
}

body {
  background: #fff;
  color: #0b093b;
  font-family: "Khula", sans-serif;
  overflow-x: hidden;
  font-size: 16px;
  font-weight: 400;
}

.container {
  width: 100%;
  max-width: 1220px;
  margin: 0 auto;
  padding: 0px 30px;
}

@media only screen and (max-width: 859px) {
  .container {
    padding: 0 15px;
  }
}

.borderRound {
  border: 3px solid;
  border-radius: 5rem;
}

.regular {
  font-weight: 400 !important;
}

.semiBold {
  font-weight: 600 !important;
}

.extraBold {
  font-weight: 800 !important;
}

/* HELLPERS */
.textCenter {
  text-align: center;
}

.animate {
  transition: 0.3s ease;
}

.pointer {
  cursor: pointer;
}

.relative {
  position: relative;
}

.textCenter {
  text-align: center;
}

.textLeft {
  text-align: left;
}

.textRight {
  text-align: right;
}

.x100 {
  width: 100%;
}

.active {
  border-bottom: 2px solid #7620ff;
  color: #7620ff;
}

.tag {
  padding: 5px 10px;
  margin: 10px 0;
}

.shadow {
  box-shadow: 0 0 13px rgba(237, 232, 237, 0.5), 0 0 13px rgba(0, 0, 0, 0.19);
  -webkit-box-shadow: 0 0 13px rgba(237, 232, 237, 0.5), 0 0 13px rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 0 0 13px rgba(237, 232, 237, 0.5), 0 0 13px rgba(0, 0, 0, 0.19);
}

/* FLEXBOX */
.flex {
  display: flex;
}

.flexSpaceCenter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flexSpaceStart {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.flexNullCenter {
  display: flex;
  align-items: center;
}

.flexColumn {
  flex-direction: column;
}

.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flexJustifyCenter {
  display: flex;
  justify-content: center;
}

.flexStart {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flexSpaceNull {
  display: flex;
  justify-content: space-between;
}

/* MARGIN PADDING SIZES */
.marginT15 {
  margin-top: 0.938rem;
}

.marginV15 {
  margin-top: 0.938rem;
  margin-bottom: 0.938rem;
}

@media only screen and (max-width: 859px) {
  .marginT15 {
    margin-top: 0.813rem;
  }
  .marginV15 {
    margin-top: 0.813rem;
    margin-bottom: 0.813rem;
  }
}

/* FONT SIZES */
.font11 {
  font-size: 0.688rem;
}

.font12 {
  font-size: 0.75rem;
}

.font13 {
  font-size: 0.813rem;
}

.font14 {
  font-size: 0.875rem;
}

.font15 {
  font-size: 0.938rem;
}

.font18 {
  font-size: 1.125rem;
}

.font20 {
  font-size: 1.25rem;
}

.font25 {
  font-size: 1.563rem;
}

.font30 {
  font-size: 1.875rem;
}

.font40 {
  font-size: 2.5rem;
}

.font60 {
  font-size: 3.75rem;
  line-height: 4.5rem;
}

@media only screen and (max-width: 859px) {
  .font11 {
    font-size: 0.688rem;
  }

  .font12 {
    font-size: 0.688rem;
  }

  .font13 {
    font-size: 0.75rem;
  }

  .font14 {
    font-size: 0.813rem;
  }

  .font15 {
    font-size: 0.813rem;
  }

  .font18 {
    font-size: 0.938rem;
  }

  .font20 {
    font-size: 1.125rem;
  }

  .font25 {
    font-size: 1.25rem;
  }

  .font30 {
    font-size: 1.5rem;
  }

  .font40 {
    font-size: 1.875rem;
  }

  .font60 {
    font-size: 2.8rem;
  }
}

/* BORDER RADIUS */
.radius8 {
  border-radius: 1rem;
}

.radius6 {
  border-radius: 0.6rem;
}

/* COLORS */
.darkColor {
  color: #0b093b;
}

.darkColorNew {
  color: #35426D;
}

.purpleColor {
  color: #7620ff;
}

.orangeColor {
  color: #f2b300;
}

.lightColor {
  color: #f5f5f5;
}

.coralColor {
  color: #c4ffff;
}

.greenColor {
  color: #49cb86;
}

.greyColor {
  color: #707070;
}

.whiteColor {
  color: #fff;
}

/* BACKGROUNDS */
.darkBg {
  background-color: #0b093b;
}

.purpleBg {
  background-color: #7620ff;
}

.orangeBg {
  background-color: #f2b300;
}

.lightBg {
  background-color: #f5f5f5;
}

.coralBg {
  background-color: #c4ffff;
}

.greenBg {
  background-color: #49cb86;
}

.greyBg {
  background-color: #707070;
}

.whiteBg {
  background-color: #fff;
}


/* // drop down */
.menu-trigger img{
  position: absolute;
  top: 20px;
  right: 20px;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  background-color: #fff;
}

.dropdown-menu{
  position: absolute;
  top: 60px;
  right: 20px;
  background-color: #fff;
  border-radius: var(--border-radius);
  padding: 10px 20px;
  width: 220px;
  border-radius: 10px;
}

.dropdown-menu::before{
  content: '';
  position: absolute;
  top: -5px;
  right: 20px;
  height: 20px;
  width: 20px;
  background: var(--secondary-bg);
  transform: rotate(45deg);
}

.dropdown-menu.active{
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition: var(--speed) ease;
}

.dropdown-menu.inactive{
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: var(--speed) ease;
}


.dropdown-menu ul li{
  padding: 8px  0;
}

.dividerBottom {
  border-bottom: 1px solid #0b093b40;
}

.dropdown-menu ul li:hover a{
  color: #7620ff;
  cursor: pointer;
}

.dropdown-menu ul li:hover img{
  opacity: 1;
  cursor: pointer;
}

.activeCard {
  border-bottom: 5px solid #7620ff;
  color: #7620ff;
}

.dropdownItem{
  display: flex;
  margin: 10px 0 10px -10px;
}

.dropdownItem img{
  max-width: 20px;
  margin-right: 10px;
  opacity: 0.5;
  transition: var(--speed);
}

.dropdownItem a{
  /* max-width: 100px; */
  margin-left: 10px;
  transition: var(--speed);
}