* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/*-----------------*/
/* Side Bar Styles */
.side-container {
  display: flex;
}

.sidebar {
  height: 100vh;
  overflow-y: auto;
  padding: 3vh;
  position: '-webkit-sticky';
  position: sticky;
  top: 0;
}

.sidebarRound {
  border-radius: 1vh;
  overflow-y: auto;
  height: 94vh;
}

.top_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fullCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  font-size: 20px;
  font-family: "Khula", sans-serif;
  font-weight: 900;
}

.routes {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.sideBarDivider {
  height: 1px;
  margin: 5px 15px;
  background-color: #D4D4D4;
}

.link {
  display: flex;
  color: #ACACAC;
  gap: 10px;
  padding: 10px;
  align-items: center;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.link:hover {
  color: #0B093B;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.active {
  color: #0B093B;
}

.icon {
  padding-left: 0;
}

.iconNoPad {
  padding-left: 8px;
}

.link_text {
  white-space: nowrap;
  padding-top: 5px;
  font-size: 15px;
  font-family: "Khula", sans-serif;
  font-weight: 600;
}

.menu {
  display: flex;
  color: #ACACAC;
  padding: 8px 10px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  justify-content: space-between;
}

.menuFocus {
  display: flex;
  color: #0B093B;
  padding: 8px 10px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  justify-content: space-between;
}

.menu_item {
  display: flex;
  gap: 10px;
}

.menu_container {
  display: flex;
  flex-direction: column;
}

.menu_container .link {
  padding-left: 20px;
  border-bottom: #fff 0.5px solid;
}

.title {
  font-size: 3rem;
  display: grid;
  place-items: center;
}

.hide {
  display: none;
}

/*   Side Bar End   */
/*------------------*/

/*-----------------------*/
/*  Common Header Start  */
.flexRow {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 2vh;
}

.flexEnd {
  padding-right: 20px;
  text-align: right;
}

.userName {
  color: #0b093b;
  font-size: 18px;
  font-family: "Khula", sans-serif;
  font-weight: 700;
}

.userRole {
  color: #707070;
  font-size: 14px;
  font-family: "Khula", sans-serif;
  font-weight: 700;
}

.userImage {
  height: 60px;
  width: 60px;
  border-radius: 10px;
}

.dividerHeader {
  height: 2px;
  margin: 0 20px 0 0;
  background-color: #D4D4D4;
}

/*  Common Header End  */
/*---------------------*/

/*-----------------------*/
/*  Dashboard Start  */
.main-container {
  height: '100%';
  width: '100%';
}

.dashCard-row {
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.dashCard {
  margin: 5px;
  width: 100px;
  font-size: 15px;
  color: #7620ff;
  font-weight: 600;
  padding: 10px 15px;
  border-radius: 8px;
  text-align: center;
  border: #7620ff 1px solid;
}

.dashCardSelected {
  margin: 5px;
  width: 100px;
  font-size: 15px;
  color: #FFFFFF;
  font-weight: 600;
  padding: 10px 15px;
  border-radius: 8px;
  text-align: center;
  border: #7620ff 1px solid;
  background-color: #7620ff;
}

.dashDataItemCard {
  display: flex;
  background-color: #FFFFFF;
  margin: 5px;
  padding: 10px;
  border-radius: 10px;
}

.divIconDash {
  background-color: #7620FF15;
  color: #7620FF;
  padding: 10px;
  align-items: center;
  text-align: 'center';
  border-radius: 10px;
}

.titleDashCard {
  color: #8A89A1;
  font-size: 15px;
  font-weight: 600;
  padding-bottom: 1px;
}

.subTitleDashCard {
  color: #707070;
  font-size: 20px;
  font-weight: 500;
  padding-top: 1px;
}

.paddingH10 {
  padding: 0 10px;
}

.imageTh {
  width: 100px;
  text-align: center;
}

.thTitle {
  color: #7620FF;
  font-size: 19px;
  font-weight: 500;
  padding: 10px;
}

.tdTitle {
  color: #0B093B;
  font-size: 16px;
  font-weight: 500;
  padding: 10px;
}

.orderImage {
  height: 40px;
  width: 40px;
  border-radius: 5px;
}

.listDataCard {
  background-color: #FFFFFF;
  margin: 25px 20px 0 5px;
  padding: 30px 20px 10px 20px;
  border-radius: 10px;
}

.table-wrapper {
  height: 46vh;
  width: '100%';
  margin-top: 10px;
  border-radius: 10px;
  overflow: hidden;
  border: #7620ff 2px solid;
  overflow-y: scroll;
}

.table-header {
  background-color: #F4EDFF;
  position: sticky;
  top: 0px;
}

.textTitle {
  color: #0B093B;
  font-size: 25px;
  font-weight: 700;
  padding: 0 10px 10px 10px;
}

.listDatashboardCard {
  background-color: #FFFFFF;
  margin: 25px 0 0 0;
  padding: 15px;
  border-radius: 10px;
}

.textTitle1 {
  color: #0B093B;
  font-size: 20px;
  font-weight: 700;
}
/*  Dashboard End  */
/*-----------------*/